import React from "react"
import { graphql, Link, StaticQuery } from "gatsby"
import { GatsbyImage } from 'gatsby-plugin-image';

import Layout from "../components/layout"
import Seo from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const KittensPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const litters = data.allSanityKitten.group
  console.log(litters)

  return (
    <Layout title={siteTitle} location={ location }>
      <Seo
        title="Available Kittens"
        keywords={[`devon rex`, `devon rex cats`, `cats`, `kittens`, `devon rex kittens`, `devon rex breeder`]}
      />

      <article className="page-template no-image">
        { litters.length === 0 && (
          <div className="post-content">
            <div>
              <h2 className="post-content-title">No kittens currently available</h2>
              <p>Please visit <Link to='/contact'>Contact</Link> to learn more and be placed on our watlist.</p>
              <p>In the meantime, check out our <Link to='/gallery'>Gallery</Link>!</p>
            </div>
          </div>
        ) }
        { litters.length > 0 && (
          <div>
            <h3>Available Kittens</h3>
            { litters.map((litter, i) => (
              <div key={ `litter-${ i }` }>
                { litter.nodes.map((kitten, i) => (
                  <div key={ `kitten-${ i }` }>
                    <div className="kitten-info">
                      <div className="kitten-fact">
                        <p><b>Gender:</b></p>
                        <p>{ kitten.gender }</p>
                      </div>
                      <div className="kitten-fact">
                        <p><b>Birthday:</b></p>
                        <p>{ kitten.birthday }</p>
                      </div>
                      <div className="kitten-fact">
                        <p><b>Available on:</b></p>
                        <p>{ kitten.availableDate }</p>
                      </div>
                    </div>
                    <div className="kitten-images">
                      { kitten.photos.map((image, i) => (
                        <GatsbyImage 
                          key={ `kitten-image-${ i }` } 
                          image={image.asset.gatsbyImageData}
                          className="image-card"
                        />
                      ) )}
                    </div>
                  </div>
                )) }
              </div>
            )) }
          </div> 
        )}
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allSanityKitten {
      group(field: birthday) {
        fieldValue
        nodes {
          gender
          birthday
          availableDate
          photos {
            asset {
              gatsbyImageData(width: 500, height: 500, fit: FILL)
            }
          }
        }
      }
    }
  }
`

const Component = props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <KittensPage location={props.location} data={data} {...props} />
    )}
  />
)

export default Component;
